import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { noop, startCase } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Wizard from "../../../../../Wizard/Wizard";
import useScreensForPersonalAuto from "./components/PersonalAuto/hooks/useScreensForPersonalAuto";
import { AxiosError } from "axios";
import useScreensForManufacturedHomeowner from "./components/ManufacturedHomeowner/hooks/useScreensForManufacturedHomeowner";
import * as ReactDOMServer from "react-dom/server";
import Helpers from "../../../../../../utils/Helpers";
import { createClaim, sendEmail } from "../../../../../../utils/endpoints";
import useScript from "../../../../hooks/useScript";
import Modal from "../../../../../Modal/Modal";
import { Spinner } from "react-bootstrap";
import Button from "../../../../../Button/Button";
import { getEmailData } from "../../../../../../utils/email-templates";

const hooksByEndpoint = {
  "personal-auto": useScreensForPersonalAuto,
  "manufactured-homeowner": useScreensForManufacturedHomeowner,
};

const Form = ({
  className,
  endpoint,
  setFormVisibility,
  exitModal,
  setExitModal,
}) => {
  const recaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_KEY;

  const { grecaptcha } = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render="${recaptchaApiKey}"`,
    "grecaptcha",
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState("");
  const [formSubmittedWarning, setFormSubmittedWarning] = useState("");
  const [setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const useScreens = hooksByEndpoint[endpoint] || noop;
  const screens = useScreens() || [];

  const schema = yup
    .object()
    .shape(
      screens.map(({ schema: s }) => s).reduce((x, s) => ({ ...x, ...s }), {}),
    );

  const { handleSubmit, ...form } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const tableGenerator = (data, i, tableTitle) => {
    return (
      <table style={{ borderCollapse: "collapse", width: "100%" }} border="1">
        <colgroup>
          <col style={{ width: "50%" }} />
          <col style={{ width: "50%" }} />
        </colgroup>
        <tbody>
          <tr>
            <td style={{ backgroundColor: "rgb(45, 194, 107)" }} colSpan="2">
              <span style={{ fontSize: "24pt" }}>
                {tableTitle} #{i + 1} Information
              </span>
            </td>
          </tr>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td>{startCase(key)}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const sendEmailToUser = async (data) => {
    await sendEmail(getEmailData(data))
      .then((response) => {
        setFormSubmitted(true);
        setFormSubmittedMessage(
          "Thank you for contact us we will be in touch with you soon!",
        );
      })
      .catch((reason = AxiosError) => {
        setFormSubmitted(true);
        setFormSubmittedMessage(
          "There was an error submitting your form. Please refresh the page and try again.",
        );
      });
  };

  const mapFormData = (data) => {
    return {
      insured: {
        policy_number: data.policyNumber,
        vehicle: {
          year: data.vehicleYear,
          make: data.vehicleMake,
          model: data.vehicleModel,
          vin: data.vehicleVinNumber,
          color: data.vehicleColor,
          damage_info: {
            is_damaged: data.isVehicleDamaged === "Yes" ? true : false,
            damage_description: data.vehicleDamageDescription,
            unrelated_prior_damage: data.unrelatedPriorDamage,
            impact_location: Number(data.pointOfImpact),
          },
          license_plate: data.licensePlateNumber,
          is_drivable: data.isVehicleDrivable === "Yes" ? true : false,
          use_type: data.vehicleDriversPurposeOfUse,
          garage_info: {
            location: {
              address: data.vehicleLocationStreetAddress,
              city: data.vehicleLocationCity,
              state: data.vehicleLocationState,
              zip_code: data.vehicleLocationZip,
            },
            name: data.businessName,
            phone_number: Helpers.formatPhoneNum(data.businessPhone),
          },
          owner: {
            first_name: data.policyHolderFirstName,
            last_name: data.policyHolderLastName,
            address: {
              address: data.policyHolderStreetAddress,
              city: data.policyHolderCity,
              state: data.policyHolderState,
              zip_code: data.policyHolderZip,
            },
            medical_info: {
              date_of_birth:
                data.sameAsVehicleOwner === "Yes"
                  ? data.vehicleDriversDateOfBirth
                  : "2000-02-29",
              is_injured:
                data.sameAsVehicleOwner === "Yes"
                  ? data.wasDriverInjured === "Yes"
                    ? true
                    : false
                  : false,
              injuries_description:
                data.sameAsVehicleOwner === "Yes"
                  ? data.driverInjuryDescription
                  : "",
              sought_medical_treatment:
                data.sameAsVehicleOwner === "Yes"
                  ? data.didDriverSeekMedical === "Yes"
                    ? true
                    : false
                  : false,
            },
            ticket_info: {
              is_issued: false,
              reason: "",
            },
            contact_info: {
              ok_to_call:
                data.policyHolderHomePhone !== "" ||
                data.policyHolderWorkPhone !== ""
                  ? true
                  : false,
              ok_to_text: data.policyHolderCellPhone !== "" ? true : false,
              ok_to_email: data.policyHolderEmail !== "" ? true : false,
              phones: [
                {
                  type: "HOME",
                  number: Helpers.formatPhoneNum(data.policyHolderHomePhone),
                },
                {
                  type: "CELL",
                  number: Helpers.formatPhoneNum(data.policyHolderCellPhone),
                },
                {
                  type: "WORK",
                  number: Helpers.formatPhoneNum(data.policyHolderWorkPhone),
                },
              ],
              emails: [
                {
                  type: "HOME",
                  address: data.policyHolderEmail,
                },
              ],
            },
            license_info: {
              number: data.vehicleDriversLicenseNumber,
              state: data.vehicleDriversLicenseState,
            },
            is_passenger: data.sameAsVehicleOwner === "No" ? true : false,
            is_driver: data.sameAsVehicleOwner === "Yes" ? true : false,
          },
          driver: {
            first_name:
              data.sameAsVehicleOwner === "Yes"
                ? data.policyHolderFirstName
                : data.vehicleDriversFirstName,
            last_name:
              data.sameAsVehicleOwner === "Yes"
                ? data.policyHolderLastName
                : data.vehicleDriversLastName,
            address: {
              address:
                data.sameAsVehicleOwner === "Yes"
                  ? data.policyHolderStreetAddress
                  : data.vehicleDriversStreetAddress,
              city:
                data.sameAsVehicleOwner === "Yes"
                  ? data.policyHolderCity
                  : data.vehicleDriversCity,
              state:
                data.sameAsVehicleOwner === "Yes"
                  ? data.policyHolderState
                  : data.vehicleDriversState,
              zip_code:
                data.sameAsVehicleOwner === "Yes"
                  ? data.policyHolderZip
                  : data.vehicleDriversZip,
            },
            medical_info: {
              date_of_birth: data.vehicleDriversDateOfBirth,
              is_injured: data.wasDriverInjured === "Yes" ? true : false,
              injuries_description: data.driverInjuryDescription,
              sought_medical_treatment:
                data.didDriverSeekMedical === "Yes" ? true : false,
            },
            ticket_info: {
              is_issued: data.didDriverReceiveTicket === "Yes" ? true : false,
              reason: data.driverTicketReason,
            },
            contact_info: {
              ok_to_call: true,
              ok_to_text: true,
              ok_to_email: true,
              phones: [
                {
                  type: "HOME",
                  number:
                    data.sameAsVehicleOwner === "Yes"
                      ? Helpers.formatPhoneNum(data.policyHolderHomePhone)
                      : Helpers.formatPhoneNum(data.vehicleDriversHomePhone),
                },
                {
                  type: "WORK",
                  number:
                    data.sameAsVehicleOwner === "Yes"
                      ? Helpers.formatPhoneNum(data.policyHolderWorkPhone)
                      : Helpers.formatPhoneNum(data.vehicleDriversWorkPhone),
                },
                {
                  type: "CELL",
                  number:
                    data.sameAsVehicleOwner === "Yes"
                      ? Helpers.formatPhoneNum(data.policyHolderCellPhone)
                      : Helpers.formatPhoneNum(data.vehicleDriversCellPhone),
                },
              ],
              emails: [
                {
                  type: "HOME",
                  address:
                    data.sameAsVehicleOwner === "Yes"
                      ? data.policyHolderEmail
                      : data.vehicleDriversEmail,
                },
              ],
            },
            license_info: {
              number: data.vehicleDriversLicenseNumber,
              state: data.vehicleDriversLicenseState,
            },
          },
          passengers:
            data?.passengers.length > 0
              ? data?.passengers?.map((passenger) => ({
                  first_name: passenger.firstName,
                  last_name: passenger.lastName,
                  address: {
                    address: passenger.streetAddress,
                    city: passenger.city,
                    state: passenger.state,
                    zip_code: passenger.zip,
                  },
                  medical_info: {
                    date_of_birth: passenger.dateOfBirth,
                    is_injured:
                      passenger.wasPassengerInjured === "Yes" ? true : false,
                    injuries_description: passenger.passengerInjuryDescription,
                    sought_medical_treatment:
                      passenger.didPassengerSeekMedical === "Yes"
                        ? true
                        : false,
                  },
                  contact_info: {
                    ok_to_call: true,
                    phones: [
                      {
                        type: "HOME",
                        number: Helpers.formatPhoneNum(passenger.homePhone),
                      },
                    ],
                  },
                }))
              : [],
        },
      },
      claimants: {
        vehicles:
          data.isAnotherVehicleInvolved === "Yes"
            ? [
                {
                  year: data.otherVehicleYear,
                  make: data.otherVehicleMake,
                  model: data.otherVehicleModel,
                  vin: data.otherVehicleVehicleVin,
                  color: data.otherVehicleVehicleColor,
                  damage_info: {
                    is_damaged:
                      data.isOtherVehicleDamaged === "Yes" ? true : false,
                    damage_description: data.otherVehicleDamageDescription,
                    unrelated_prior_damage:
                      data.otherVehicleUnrelatedPriorDamage,
                    impact_location: Number(
                      data.otherVehicleInitialPointOfImpact,
                    ),
                  },
                  license_plate: data.otherVehicleLicensePlateNumber,
                  is_drivable:
                    data.isOtherVehicleDrivable === "Yes" ? true : false,
                  owner: {
                    first_name: data.otherVehicleOwnerFirstName,
                    last_name: data.otherVehicleOwnerLastName,
                    address: {
                      address: data.otherVehicleOwnerStreetAddress,
                      city: data.otherVehicleOwnerCity,
                      state: data.otherVehicleOwnerState,
                      zip_code: data.otherVehicleOwnerZip,
                    },
                    medical_info: {
                      date_of_birth:
                        data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                          ? data.otherVehicleDriversDateOfBirth
                          : "",
                      is_injured:
                        data.otherVehicleDriversInjured === "Yes"
                          ? true
                          : false,
                      injuries_description:
                        data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                          ? data.otherVehicleDriversInjuryDescription
                          : "",
                      sought_medical_treatment:
                        data.otherVehicleDriversDidSeekMedical === "Yes"
                          ? true
                          : false,
                    },
                    ticket_info: {
                      is_issued:
                        data.otherVehicleDriversDidReceiveTicket === "Yes"
                          ? true
                          : false,
                      reason: data.otherVehicleDriversTicketReason,
                    },
                    contact_info: {
                      ok_to_call:
                        data.otherVehicleOwnerHomePhone ||
                        data.otherVehicleOwnerWorkPhone
                          ? true
                          : false,
                      ok_to_text: data.otherVehicleOwnerCellPhone
                        ? true
                        : false,
                      ok_to_email: data.otherVehicleOwnerEmail ? true : false,
                      phones: [
                        {
                          type: "HOME",
                          number: Helpers.formatPhoneNum(
                            data.otherVehicleOwnerHomePhone,
                          ),
                        },
                        {
                          type: "WORK",
                          number: Helpers.formatPhoneNum(
                            data.otherVehicleOwnerWorkPhone,
                          ),
                        },
                        {
                          type: "CELL",
                          number: Helpers.formatPhoneNum(
                            data.otherVehicleOwnerCellPhone,
                          ),
                        },
                      ],
                      emails: [
                        {
                          type: "HOME",
                          address: data.otherVehicleOwnerEmail,
                        },
                      ],
                    },
                    license_info: {
                      number: data.otherVehicleDriversLicenseNumber,
                      state: data.otherVehicleDriversLicenseState,
                    },
                    is_driver: true,
                  },
                  driver: {
                    first_name:
                      data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                        ? data.policyHolderFirstName
                        : data.otherVehicleDriversFirstName,
                    last_name:
                      data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                        ? data.policyHolderLastName
                        : data.otherVehicleDriversLastName,
                    address: {
                      address:
                        data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                          ? data.policyHolderLastName
                          : data.otherVehicleDriversStreetAddress,
                      city:
                        data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                          ? data.policyHolderCity
                          : data.otherVehicleDriversCity,
                      state:
                        data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                          ? data.policyHolderState
                          : data.otherVehicleDriversState,
                      zip_code:
                        data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                          ? data.policyHolderZip
                          : data.otherVehicleDriversZip,
                    },
                    medical_info: {
                      date_of_birth: data.otherVehicleDriversDateOfBirth,
                      is_injured:
                        data.otherVehicleDriversInjured === "Yes"
                          ? true
                          : false,
                      injuries_description:
                        data.otherVehicleDriversInjuryDescription,
                      sought_medical_treatment:
                        data.otherVehicleDriversDidSeekMedical === "Yes"
                          ? true
                          : false,
                    },
                    ticket_info: {
                      is_issued:
                        data.otherVehicleDriversDidReceiveTicket === "Yes"
                          ? true
                          : false,
                      reason: data.otherVehicleDriversTicketReason,
                    },
                    contact_info: {
                      ok_to_call: true,
                      ok_to_text: true,
                      ok_to_email: true,
                      phones: [
                        {
                          type: "HOME",
                          number:
                            data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                              ? Helpers.formatPhoneNum(
                                  data.otherVehicleOwnerHomePhone,
                                )
                              : Helpers.formatPhoneNum(
                                  data.otherVehicleDriversHomePhone,
                                ),
                        },
                        {
                          type: "WORK",
                          number:
                            data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                              ? Helpers.formatPhoneNum(
                                  data.otherVehicleOwnerWorkPhone,
                                )
                              : Helpers.formatPhoneNum(
                                  data.otherVehicleDriversWorkPhone,
                                ),
                        },
                        {
                          type: "CELL",
                          number:
                            data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                              ? Helpers.formatPhoneNum(
                                  data.otherVehicleOwnerCellPhone,
                                )
                              : Helpers.formatPhoneNum(
                                  data.otherVehicleDriversCellPhone,
                                ),
                        },
                      ],
                      emails: [
                        {
                          type: "HOME",
                          address:
                            data.otherVehicleDriversSameAsVehicleOwner === "Yes"
                              ? data.otherVehicleOwnerEmail
                              : data.otherVehicleDriversEmail,
                        },
                      ],
                    },
                    license_info: {
                      number: data.otherVehicleDriversLicenseNumber,
                      state: data.otherVehicleDriversLicenseState,
                    },
                  },
                  passengers:
                    data?.passengersClaimant.length > 0
                      ? data?.passengersClaimant?.map((passenger) => ({
                          first_name: passenger.firstName,
                          last_name: passenger.lastName,
                          address: {
                            address: passenger.streetAddress,
                            city: passenger.city,
                            state: passenger.state,
                            zip_code: passenger.zip,
                          },
                          medical_info: {
                            date_of_birth: passenger.dateOfBirth,
                            is_injured: passenger.wasPassengerInjured,
                            injuries_description:
                              passenger.passengerInjuryDescription,
                            sought_medical_treatment:
                              passenger.didPassengerSeekMedical === "Yes"
                                ? true
                                : false,
                          },
                          contact_info: {
                            ok_to_call: passenger.homePhone ? true : false,
                            phones: [
                              {
                                type: "HOME",
                                number: Helpers.formatPhoneNum(
                                  passenger.homePhone,
                                ),
                              },
                            ],
                          },
                        }))
                      : [],
                  insurance_info: {
                    name: data.OtherVehicleInsuranceCompanyName,
                    policy_number:
                      data.otherVehicleInsuranceCompanyPolicyNumber,
                  },
                },
              ]
            : [],
        pedestrians:
          data.pedestriansInvolved === "Yes"
            ? [
                {
                  first_name: data.pedestriansFirstName,
                  last_name: data.pedestriansLastName,
                  address: {
                    address: data.pedestriansStreetAddress,
                    city: data.pedestriansCity,
                    state: data.pedestriansState,
                    zip_code: data.pedestriansZip,
                  },
                  medical_info: {
                    date_of_birth: data.pedestrianDateOfBirth,
                    is_injured:
                      data.pedestriansInjured === "Yes" ? true : false,
                    injuries_description: data.pedestriansInjuryDescription,
                    sought_medical_treatment:
                      data.pedestrianDidSeekMedical === "Yes" ? true : false,
                  },
                  contact_info: {
                    ok_to_call:
                      data.pedestriansHomePhone || data.pedestriansWorkPhone
                        ? true
                        : false,
                    ok_to_text: data.pedestriansCellPhone ? true : false,
                    ok_to_email: data.pedestriansEmail ? true : false,
                    phones: [
                      {
                        type: "HOME",
                        number: Helpers.formatPhoneNum(
                          data.pedestriansHomePhone,
                        ),
                      },
                      {
                        type: "WORK",
                        number: Helpers.formatPhoneNum(
                          data.pedestriansWorkPhone,
                        ),
                      },
                      {
                        type: "CELL",
                        number: Helpers.formatPhoneNum(
                          data.pedestriansCellPhone,
                        ),
                      },
                    ],
                    emails: [
                      {
                        type: "HOME",
                        address: data.pedestriansEmail,
                      },
                    ],
                  },
                },
              ]
            : [],
        property_owners:
          data.fixedObjectInvolved === "Yes"
            ? {
                first_name: data.fixedObjectOwnersFirstName,
                last_name: data.fixedObjectOwnersLastName,
                address: {
                  address: data.fixedObjectOwnersStreetAddress,
                  city: data.fixedObjectOwnersCity,
                  state: data.fixedObjectOwnersState,
                  zip_code: data.fixedObjectOwnersZip,
                },
                contact_info: {
                  ok_to_call: data.fixedObjectOwnersHomePhone ? true : false,
                  ok_to_text: false,
                  ok_to_email: data.fixedObjectOwnersEmail ? true : false,
                  phones: [
                    {
                      type: "HOME",
                      number: Helpers.formatPhoneNum(
                        data.fixedObjectOwnersHomePhone,
                      ),
                    },
                  ],
                  emails: [
                    {
                      type: "HOME",
                      address: Helpers.formatPhoneNum(
                        data.fixedObjectOwnersEmail,
                      ),
                    },
                  ],
                },
                property_info: {
                  description: data.fixedObjectDescription,
                  damage_description: data.fixedObjectDamage,
                  address: {
                    address: data.fixedObjectOwnersStreetAddress,
                    city: data.fixedObjectOwnersCity,
                    state: data.fixedObjectOwnersState,
                    zip_code: data.fixedObjectOwnersZip,
                  },
                },
              }
            : {},
      },
      reporter: {
        first_name: data.reporterFirstName,
        last_name: data.reporterLastName,
        type: data.insuredType !== "" ? data.insuredType : data.notInsuredType,
        contact_info: {
          phones: [
            {
              type: "HOME",
              number: Helpers.formatPhoneNum(data.reporterPhone),
            },
          ],
          emails: [
            {
              type: "HOME",
              address: data.reporterEmail,
            },
          ],
        },
        photo_estimating_info: {
          can_contact: true,
          phone_number: Helpers.formatPhoneNum(data.reporterPhone),
          email_address: data.reporterEmail,
        },
      },
      loss_details: {
        loss_date: data.dateOfAccident,
        loss_time: Helpers.convertTimeTo12Hr(data.timeOfAccident),
        address: {
          address: data.locationOfAccidentStreetAddress,
          city: data.locationOfAccidentCity,
          state: data.locationOfAccidentState,
          zip_code: data.locationOfAccidentZip,
        },
        description: data.accidentDescription,
        comments: data.additionalComments,
        police_info: {
          notified: data.policeNotified === "Yes" ? true : false,
          case_number:
            data.policeNotified === "Yes" ? data.policeReportNumber : "0",
          department_name: data.policeDepartment,
          reported_method: data.reportFiled,
        },
        loss_type: data.damageType,
      },
    };
  };

  const trimObjectFields = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        trimObjectFields(obj[key]);
      }
    });
    return obj;
  };

  const removeBlankPhonesAndEmails = (obj) => {
    for (const key in obj) {
      if (key === "phones" || key === "emails") {
        obj[key] = obj[key].filter(
          (item) => item.number !== "" && item.address !== "",
        );
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        obj[key] = removeBlankPhonesAndEmails(obj[key]);
      }
    }
    return obj;
  };

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      if (endpoint === "manufactured-homeowner") {
        data?.claimants?.map((claimant, i) => {
          var claim = ReactDOMServer.renderToStaticMarkup(
            tableGenerator(claimant, i, "Claimant"),
          );
          var key = `claimTable${i}`;
          data[key] = claim;
        });
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise
            .execute({ action: "submit" })
            .then(async function (token) {
              await sendEmailToUser(data)
                .then((response) => {
                  setIsLoading(false);
                  setFormSubmitted(true);
                  setFormSubmittedWarning(
                    "Please contact Unique Insurance +1-866-426-8842 for more information",
                  );
                })
                .catch((reason = AxiosError) => {
                  setIsLoading(false);
                  setFormSubmitted(true);
                  setFormError(true);
                  setFormSubmittedMessage(reason?.message);
                  setFormSubmittedWarning(
                    "An error has occurred, please contact Unique Insurance +1-866-426-8842",
                  );
                });
            });
        });
      } else if (endpoint === "personal-auto") {
        data?.passengers?.map((passenger, i) => {
          var claim = ReactDOMServer.renderToStaticMarkup(
            tableGenerator(passenger, i, "Passenger"),
          );
          var key = `passengerTable${i}`;
          data[key] = claim;
        });
        data?.passengersClaimant?.map((passenger, i) => {
          var claim = ReactDOMServer.renderToStaticMarkup(
            tableGenerator(passenger, i, "Other Vehicle Passenger"),
          );
          var key = `passengerClaimantTable${i}`;
          data[key] = claim;
        });

        var submittedData = removeBlankPhonesAndEmails(
          trimObjectFields(mapFormData(data)),
        );
        grecaptcha.enterprise.ready(function () {
          grecaptcha.enterprise
            .execute({ action: "submit" })
            .then(async function (token) {
              await sendEmailToUser(data);
              await createClaim(submittedData)
                .then((response) => {
                  setIsLoading(false);
                  setFormSubmitted(true);
                  setFormSubmittedWarning(
                    "Please contact Unique Insurance +1-866-426-8842 for more information",
                  );
                })
                .catch((reason = AxiosError) => {
                  setIsLoading(false);
                  setFormSubmitted(true);
                  setFormError(true);
                  setFormSubmittedMessage(reason?.message);
                  setFormSubmittedMessage(
                    "An error has occurred, please contact Unique Insurance +1-866-426-8842",
                  );
                });
            });
        });
      }
    },
    [grecaptcha],
  );

  return (
    <div>
      {!formSubmitted ? (
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
          <Wizard form={form}>
            {screens.map(({ Component }, i) => (
              <Component
                form={form}
                key={i}
                onSubmitForm={handleSubmit(onSubmit)}
              />
            ))}
          </Wizard>
          {isLoading && (
            <Modal
              isCloseButtonVisible={false}
              onClose={() => {}}
              open={true}
              width="max-content"
              panelClassName="loading__modal"
            >
              <Spinner animation="grow"> </Spinner>
              <p>Processing your request</p>
            </Modal>
          )}
          {exitModal && (
            <Modal
              isCloseButtonVisible={true}
              onClose={() => {
                setExitModal(false);
              }}
              open={exitModal}
              width="max-content"
              panelClassName={"ClaimFormBlock__modal__warning"}
            >
              <p>Are you sure you want to exit?</p>
              <Button
                onClick={() => {
                  setExitModal(false);
                  setFormVisibility(false);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setExitModal(false);
                }}
              >
                No
              </Button>
            </Modal>
          )}
        </form>
      ) : (
        <div>
          <h3>{formSubmittedMessage}</h3>
          <h3>{formSubmittedWarning}</h3>
        </div>
      )}
    </div>
  );
};

export default Form;
