import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import SEO from "../../utils/SEO";
import Announcement from "../../components/Banners/Announcement/Announcement";
import Footer from "../../components/Footer/Footer";
import MainMenus from "../../components/MainMenus/MainMenus";
import PageBlocks from "../../components/PageBlocks/PageBlocks";
import UrgentBanner from "../../components/Banners/UrgentBanner/UrgentBanner";

const Page = ({
  announcement,
  footer,
  urgentBanner,
  mapDatas,
  page,
}) => {
  const {
    hideFromSiteMap,
    pageBlocks,
    pageDescriptionSEO,
    pageTitleSEO,
    slug,
  } = page;

  const companyInfo = useMemo(
    () => ({
      address: footer.address,
      email: footer.email,
      linkedinUrl: footer.linkedinUrl,
    }),
    [footer.address, footer.email, footer.linkedinUrl],
  );

  // on mount, scroll to top of page
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  // adjust position of main links based on
  // whether or not the banner is visible
  const announcementBannerRef = useRef(null);
  const urgentBannerRef = useRef(null);
  // const { bannerLink, bannerText } = urgentBanner;
  const [announcementBannerHeight, setannouncementBannerHeight] = useState(0);
  useEffect(() => {
    const resize = () => {
      const height = announcementBannerRef.current?.offsetHeight;

      if (height) {
        setannouncementBannerHeight(height);
      }
    };
    const debouncedResize = debounce(resize, 300);

    if (announcement.showBanner && announcementBannerRef.current) {
      resize();
      window.addEventListener("resize", debouncedResize, false);
    }

    return () => window.removeEventListener("resize", debouncedResize, false);
  }, [announcement.showBanner]);

  return (
    <div className={clsx("Page", `page-${slug}`)}>
      <SEO
        description={pageDescriptionSEO}
        indexStatus={hideFromSiteMap}
        title={pageTitleSEO}
      />

      <Announcement
        announcement={announcement}
        popupClassName="Page__announcement__popup"
        ref={announcementBannerRef}
      />

      <MainMenus
        announcementBannerHeight={announcementBannerHeight}
        className="Page__mainMenus"
        companyInfo={companyInfo}
        // mainMenus={mainMenus}
      />

      <main className="Page__main">
        { pathname == "/" &&
          <UrgentBanner
            // Hardcoded message, will be replaced once backend is fixed
            banner={{showBanner: true, bannerLink: "https://myuniqueins.com/?page_id=651", bannerText: "Victims of Hurricane Milton please report claims for damage to your manufactured home online by clicking here" }}
            ref={urgentBannerRef}
          />
        }
        <PageBlocks pageBlocks={pageBlocks} mapDatas={mapDatas} />
      </main>

      <Footer
        className="Page__footer"
        companyInfo={companyInfo}
        footer={footer}
      />
    </div>
  );
};

export default Page;
