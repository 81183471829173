import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "../../../../Modal/Modal";
import useHeaderAs from "../../../hooks/useHeaderAs";
import useIsBlockVisible from "../../../hooks/useIsBlockVisible";
import HeaderParagraphBlock from "../../ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock";
import Form from "./components/Form";

const ClaimFormBlock = ({ data, className, ...rest }) => {
  const endpoint = data.endpoint;

  const [ref, headerAs] = useHeaderAs("h1", "h2");
  const [isFormVisible, setFormVisibility] = useState(false);
  const isComponentVisible = useIsBlockVisible(data, [
    "header",
    "body",
    "buttonText",
    "displayForm",
  ]);
  const [exitModal, setExitModal] = useState(false);
  var loginText = "";
  var loginLink = "";
  const insuredPortalUrl = "https://stonegateins.com/insured-mh-logins";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target.children[0]?.children[0]?.classList.value ===
        "Modal__panel ClaimFormBlock__modal__panel"
      ) {
        setExitModal(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  if (!isComponentVisible) return;

  if (endpoint === "personal-auto") {
    loginText = "Auto Login";
    loginLink = "https://policyholder.uniqueinsuranceco.com/login";
  } else {
    loginText = "Manufactured Homeowner Login";
    loginLink = "https://policyholder.myuniqueins.com/login";
  }
  return (
    <section
      className={clsx("ClaimFormBlock__wrapper", className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="ClaimFormBlock">
          <HeaderParagraphBlock
            as="div"
            data={{
              body: data.body,
              buttonText: data.buttonText,
              disabled: !data.displayForm,
              header: data.header,
              loginButton: endpoint === "personal-auto" ? true : false,
              loginText: loginText,
              loginLink: loginLink,
              onClick: () => setFormVisibility(true),
            }}
            headerAs={headerAs}
          />
          {data.buttonText && data.displayForm && (
            <Modal
              isCloseButtonVisible={false}
              panelClassName="ClaimFormBlock__modal__panel"
              onClose={() => setFormVisibility(false)}
              open={isFormVisible}
              title={data.buttonText}
              titleClassName="ClaimFormBlock__modal__title"
            >
              <Form
                exitModal={exitModal}
                setExitModal={setExitModal}
                setFormVisibility={setFormVisibility}
                className="ClaimFormBlock__form"
                endpoint={data.endpoint}
              />
            </Modal>
          )}
          {endpoint !== "personal-auto" && (
            <Row className="ClaimFormBlock__loginButton">
              <Col xs={12} md={6} className="mt-sm-5 mt-5 mt-md-0">
                <a
                  className="Button Button__Grey PaymentFormBlock__button"
                  href={insuredPortalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Manufactured Home Login
                </a>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ClaimFormBlock;
