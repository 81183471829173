import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";
import { Col, Container, Row } from "react-bootstrap";
import useHeaderAs from "../../../hooks/useHeaderAs";
import useIsBlockVisible from "../../../hooks/useIsBlockVisible";
import Form from "./components/Form";
import Markdown from "markdown-to-jsx";
import DividerBlock from "../../ContentBlocks/DividerBlock/DividerBlock";

const AgentFormBlock = ({ data, className, ...rest }) => {
  // TODO: wire up strapi to return options for `white`, `black`
  const [ref, headerAs] = useHeaderAs("h1", "h2");
  const isVisible = useIsBlockVisible(data, ["header", "displayForm"]);
  const agentPortalUrl = "https://stonegateins.com/agent-mh-logins";

  if (!isVisible) return;

  return (
    <section
      className={clsx("AgentFormBlock__wraper", className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="AgentFormBlock">
          {data.header && (
            <div className="AgentFormBlock__header__wrapper">
              {headerAs &&
                (() => {
                  const Header = headerAs;
                  return (
                    <Header className="AgentFormBlock__header">
                      {parse(data.header)}
                    </Header>
                  );
                })()}
            </div>
          )}
          {data.displayForm && <Form className="AgentFormBlock__form" />}
          <DividerBlock />
          <div className="AgentFormBlock__columns">
            <div className="AgentFormBlock__column">
              <Markdown
                className="AgentFormBlock__column__text"
                options={{ forceBlock: true }}
              >
                {data.leftColumnText}
              </Markdown>
            </div>
            <div className="AgentFormBlock__column">
              <Markdown
                className="AgentFormBlock__column__text"
                options={{ forceBlock: true }}
              >
                {data.rightColumnText}
              </Markdown>
            </div>
          </div>
          <div className="AgentFormBlock">
            <div className="AgentFormBlock__header">
              <h1>Already a Unique Partner?</h1>
            </div>
            <Row className="justify-content-center">
              <Col xs={12} md={5}>
                <a
                  className="Button AgentFormBlock__ButtonWrapper"
                  href="https://www.uniqueinsuranceco.com/DiamondWeb/(S(mpqblmnptdcmbwbqgocyj1wc))/UniqueAgency/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Personal/Commercial Auto Login
                </a>
              </Col>
              <Col xs={12} md={5} className="mt-sm-5 mt-5 mt-md-0">
                <a
                  className="Button AgentFormBlock__ButtonWrapper"
                  href={agentPortalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Manufactured Home Login
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AgentFormBlock;
