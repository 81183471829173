import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { ReactComponent as SVGLogo } from "../../../../assets/logo.svg";

const Logo = ({ areMainLinksVisible, className }) => (
  <Link
    className={clsx("Logo__wrapper", className, { areMainLinksVisible })}
    to="/"
  >
    <SVGLogo className="Logo" />
  </Link>
);

export default Logo;
