import React, { useMemo } from "react";
import RadioGroup from "../../../../../../../../RadioGroup/RadioGroup";
import useScreen from "../hooks/useScreen";

const Screen = ({ form, screenIndex, wizard }) => {
  const insuredValue = form?.watch("insured");
  const insuredTypeValue = form?.watch("insuredType");
  const notInsuredTypeValue = form?.watch("notInsuredType");
  const insuredTypeOtherValue = form?.watch("insuredTypeOther");
  const notInsuredTypeOtherValue = form?.watch("notInsuredTypeOther");

  const isInvalid = useMemo(() => {
    if (!insuredValue) return true;
    if (insuredValue === "Yes" && !insuredTypeValue) return true;
    if (insuredValue === "No" && !notInsuredTypeValue) return true;
    if (insuredTypeValue === "Other" && !insuredTypeOtherValue) return true;
    if (notInsuredTypeValue === "Other" && !notInsuredTypeOtherValue)
      return true;
    return false;
  }, [
    insuredValue,
    insuredTypeValue,
    notInsuredTypeValue,
    insuredTypeOtherValue,
    notInsuredTypeOtherValue,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <RadioGroup
        error={form?.formState.errors.insured}
        form={form}
        label="Are you an insured or representing an insured with Unique Insurance?"
        name="insured"
        onChange={() => form?.trigger("insured")}
        touched={form?.formState.touchedFields.insured}
      >
        <span value="Yes">Yes</span>
        <span value="No">No</span>
      </RadioGroup>

      <span aria-hidden="true" />

      {insuredValue === "Yes" && (
        <RadioGroup
          error={form?.formState.errors.insuredType}
          form={form}
          isStacked
          name="insuredType"
          onChange={() => form?.trigger("insuredType")}
          otherOption={{
            error: form?.formState.errors.insuredTypeOther,
            name: "insuredTypeOther",
            touched: form?.formState.touchedFields.insuredTypeOther,
            ...form?.register("insuredTypeOther", {
              onBlur: () => form?.trigger("insuredTypeOther"),
            }),
          }}
          touched={form?.formState.touchedFields.insuredType}
        >
          <span value="INSURED">I was the person involved in an accident</span>
          <span value="INSURED_ATTORNEY">
            I am an attorney representing an insured
          </span>
          <span value="INSURED_AGENT">
            I am an agent representing an insured
          </span>
        </RadioGroup>
      )}

      {insuredValue === "No" && (
        <RadioGroup
          error={form?.formState.errors.notInsuredType}
          form={form}
          isStacked
          name="notInsuredType"
          onChange={() => form?.trigger("notInsuredType")}
          otherOption={{
            error: form?.formState.errors.notInsuredTypeOther,
            name: "notInsuredTypeOther",
            touched: form?.formState.touchedFields.notInsuredTypeOther,
            ...form?.register("notInsuredTypeOther", {
              onBlur: () => form?.trigger("notInsuredTypeOther"),
            }),
          }}
          touched={form?.formState.touchedFields.notInsuredType}
        >
          <span value="CLAIMANT">
            I am a person who was involved in an accident with a Unique insured
          </span>
          <span value="CLAIMANT_AGENT">
            I am an attorney representing a person who was involved in an
            accident with a Unique insured
          </span>
          <span value="CLAIMANT_ATTORNEY">
            I am an agent representing a person who was involved in an accident
            with a Unique Insured
          </span>
        </RadioGroup>
      )}
    </>
  );
};

export default Screen;
